var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card vm-card-widget border m-1"},[_c('div',{staticClass:"card-header"},[_vm._v(" List Vending Machine yang dikelola monster ")]),_c('div',{staticClass:"card-body pl-4 pr-4 pt-4"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"row p-3 vm-card-widget border mb-3"},[_c('div',{staticClass:"col-md-3"},[_c('div',[_c('span',{staticClass:"vm-card-merchant-label-type"},[_vm._v(_vm._s(item.name))])]),_c('div',[_c('span',{staticClass:"vm-card-merchant-value"},[_vm._v(_vm._s(item.vending_id))])]),_c('div',[_c('span',{staticClass:"vm-card-merchant-value"},[_vm._v(_vm._s(item.address))])])]),_vm._m(0,true),_c('div',{staticClass:"col-md-3 text-right"},[_vm._m(1,true),_c('div',[_c('span',{staticClass:"vm-card-merchant-value-calculate"},[_vm._v(_vm._s(item.count ? item.count : 0))])])]),_c('div',{staticClass:"col-md-3 text-right"},[_vm._m(2,true),_c('div',[_c('span',{staticClass:"vm-card-merchant-value-calculate"},[_vm._v("Rp "+_vm._s(item.value ? _vm.formatPrice(item.value) : 0))])])]),_c('div',{staticClass:"col-md-1 align-self-center text-right"},[_c('router-link',{attrs:{"to":{
              name: 'AssignVendingDinamis',
              query: { vendingId: item.id }
              // params: {
              //   id: item.id,
              //   row: item.row_slot ? item.row_slot : 5,
              //   col: item.col_slot ? item.col_slot : 8,
              // },
            }}},[_c('span',[_c('span',{staticClass:"fas fa-chevron-right text-blue"})])])],1)])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('div',[_c('span',{staticClass:"badge badge-pill badge-success"},[_vm._v("Ready")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"vm-card-merchant-label-type"},[_vm._v("Jumlah Transaksi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"vm-card-merchant-label-type"},[_vm._v("Nilai Transaksi")])])
}]

export { render, staticRenderFns }