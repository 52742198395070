<template>
  <div class="info-box vm-info-box">
    <span class="info-box-icon">
      <img :src="imageUrl"/>
    </span>
    <div class="info-box-content">
      <span class="info-box-number">{{ value }}</span>
      <span class="info-box-text">{{ label }}<i class="fas fa-chevron-right"></i></span>
    </div>
  </div>
</template>
<script>
import "@assets/scss/info-box.scss"

export default {
  name: 'info-box',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    }
  }
}
</script>