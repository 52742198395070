<template>
  <div>
    <div>
      <!-- Widget -->
      <div class="row">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <info-box
                :imageUrl="require('@assets/img/dashboard/ic_beroperasi.svg')"
                :label="'Total Mesin Beroperasi'"
                :value="'0'"
              />
            </div>
            <div class="col-md-6 col-sm-12">
              <info-box
                :imageUrl="require('@assets/img/dashboard/ic_transaksi.svg')"
                :label="'Total dgn transaksi'"
                :value="'0'"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <info-box
                :imageUrl="require('@assets/img/dashboard/ic_bermasalah.svg')"
                :label="'Mesin Bermasalah'"
                :value="'0'"
              />
            </div>
            <div class="col-md-6 col-sm-12">
              <info-box
                :imageUrl="require('@assets/img/dashboard/ic_perbaikan.svg')"
                :label="'Dalam perbaikan'"
                :value="'0'"
              />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <card-calculate
                :imageUrl="require('@assets/img/dashboard/header_trafic.png')"
                :label="'Trafik Game'"
                :value="0"
                :value_detail="'clicks'"
                :calculate="0"
                :calculate_detail="'Last month'"
                :color="'#3267E3'"
              />
            </div>
            <div class="col-md-6 col-sm-12">
              <card-calculate
                :imageUrl="require('@assets/img/dashboard/header_profit.png')"
                :label="'Revenue'"
                :value="
                  revenue?.curren_value ? formatPrice(revenue?.curren_value) : 0
                "
                :value_detail="'IDR'"
                :calculate="revenue?.percentage_value.toFixed(2) ?? 0"
                :calculate_detail="'Last month'"
                :color="'#55B71A'"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Chart -->
      <div class="row">
        <div class="col-12">
          <div class="card vm-card-widget">
            <div class="card-header">Highest Buying Time</div>
            <div class="card-body p-3">
              <ul
                class="nav nav-tabs vm-nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li
                  v-for="(item, index) in days"
                  class="nav-item"
                  :key="index"
                  v-on:click="handleChangeTab(item)"
                >
                  <a
                    :class="
                      item === selectedDay ? 'nav-link active' : 'nav-link'
                    "
                    role="tab"
                    >{{ item.toUpperCase() }}</a
                  >
                </li>
              </ul>
              <div v-if="barChartData">
                <bar-chart :chartData="barChartData[selectedDay]" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-6">
          <card-product />
        </div> -->
      </div>
      <!-- <div class="row">
        <div class="col-lg-3 col-6">
         
          <div class="small-box bg-white p-1">
            <div class="inner" v-if="count">
              <p>Jumlah Penjualan Total</p>
              <h3>{{ count.curren_count }}</h3>
              <p v-if="count.status_value == 'naik'">
                <span style="color:#3267E3">
                  <i class="fas fa-arrow-up"></i> {{ count.percentage_count }}%
                </span>
                from last month
              </p>
              <p v-if="count.status_value == 'turun'">
                <span style="color:red">
                  <i class="fas fa-arrow-down"></i>
                  {{ count.percentage_count }}%
                </span>
                from last month
              </p>
            </div>
          </div>
        </div>
       
        <div class="col-lg-3 col-6">
         
          <div class="small-box bg-white p-1">
            <div class="inner" v-if="count">
              <p>Nilai Transaksi Total</p>
              <h3>Rp {{ formatPrice(count.curren_value) }}</h3>
              <p v-if="count.status_count == 'naik'">
                <span style="color:#3267E3">
                  <i class="fas fa-arrow-up"></i> {{ count.percentage_value }}%
                </span>
                from last month
              </p>
              <p v-if="count.status_count == 'turun'">
                <span style="color:red">
                  <i class="fas fa-arrow-down"></i>
                  {{ count.percentage_value }}%
                </span>
                from last month
              </p>
            </div>
          </div>
        </div>
       
      </div> -->
      <div>
        <div class="row">
          <div class="col">
            <ul class="nav nav-tabs vm-nav-tabs-summary" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="custom-tabs-four-merchant-tab"
                  href="#custom-tabs-four-merchant"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="custom-tabs-four-merchant"
                  aria-selected="true"
                  >Merchant
                  <!-- <span class="badge vm-badge ml-2">5</span> -->
                </a>
              </li>
              <li class="nav-item" v-if="userRole == 'ROLE_MERCHANT'">
                <a
                  class="nav-link"
                  id="custom-tabs-four-monster-tab"
                  data-toggle="pill"
                  href="#custom-tabs-four-monster"
                  role="tab"
                  aria-controls="custom-tabs-four-monster"
                  aria-selected="false"
                  >Monster
                  <!-- <span class="badge vm-badge ml-2">3</span> -->
                </a>
              </li>
            </ul>
            <div class="tab-content" id="custom-tabs-four-tabContent">
              <div
                class="tab-pane fade show active"
                id="custom-tabs-four-merchant"
                role="tabpanel"
                aria-labelledby="custom-tabs-four-merchant-tab"
              >
                <card-merchant :items="merchantList"></card-merchant>
              </div>
              <div
                class="tab-pane fade"
                id="custom-tabs-four-monster"
                role="tabpanel"
                aria-labelledby="custom-tabs-four-monster-tab"
              >
                <div class="container-fluid" v-if="userRole == 'ROLE_MERCHANT'">
                  <div class="row ml-2 pb-3">
                    <div class="col-sm-6">
                      <div
                        class="row"
                        style="align-items: center"
                        v-if="merchant"
                      >
                        <img
                          :src="merchant.image"
                          style="width: 150px; height: 150px"
                        />
                        <div class="col ml-2">
                          <p class="label1">{{ merchant.name }}</p>
                          <p class="label2">ID : {{ merchant.id }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-3 col-xs-4">
                      <div class="small-box bg-white p-1">
                        <div class="inner" v-if="count">
                          <p>Jumlah Penjualan Total</p>
                          <h3>{{ merchant?.count || 0 }}</h3>
                          <p v-if="count.status_value == 'naik'">
                            <span style="color: #3267e3">
                              <i class="fas fa-arrow-up"></i>
                              {{ count.percentage_count }}%
                            </span>
                            from last month
                          </p>
                          <p v-if="count.status_value == 'turun'">
                            <span style="color: red">
                              <i class="fas fa-arrow-down"></i>
                              {{ count.percentage_count }}%
                            </span>
                            from last month
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- /.col -->
                    <div class="col-lg-3 col-xs-4">
                      <div class="small-box bg-white p-1">
                        <div class="inner" v-if="count">
                          <p>Nilai Transaksi Total</p>
                          <h3>Rp {{ formatPrice(merchant?.value || 0 ) }}</h3>
                          <p v-if="count.status_count == 'naik'">
                            <span style="color: #3267e3">
                              <i class="fas fa-arrow-up"></i>
                              {{ count.percentage_value }}%
                            </span>
                            from last month
                          </p>
                          <p v-if="count.status_count == 'turun'">
                            <span style="color: red">
                              <i class="fas fa-arrow-down"></i>
                              {{ count.percentage_value }}%
                            </span>
                            from last month
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.row -->
                  <card-vending :items="vendingList"></card-vending>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- /.container-fluid -->
  </div>
</template>
<script>
const { console } = window;
import axios from "axios";
import InfoBox from "@components/widget/InfoBox.vue";
import CardCalculate from "@components/widget/CardCalculate.vue";
import CardMerchant from "@components/widget/CardMerchant.vue";
import CardVending from "@components/widget/CardVending.vue";
import CardProduct from "@components/widget/CardProduct.vue";
import BarChart from "@components/BarChart.vue";

import "@assets/scss/pages/dashboard.scss";

export default {
  components: {
    InfoBox,
    CardCalculate,
    CardMerchant,
    CardVending,
    // CardProduct,
    BarChart,
  },
  data() {
    return {
      count: {},
      merchantList: null,
      vendingList: null,
      userRole: "",
      selectedDay: "mon",
      days: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      merchant: null,
      revenue: null,
      barChartData: {},
      config: {
        columns: [
          {
            title: "Vending Id",
            data: "vending_id",
          },
          {
            title: "Nama Vending",
            data: "name",
          },
          {
            title: "Alamat",
            data: "address",
          },
          {
            title: "Action",
            data: "id",
            render: (val) => {
              return '<div @click="showModal"> <span class="label label-info">Details</span></div>';
            },
          },
        ],
        url: this.Api.base + "/vending",
        order: [[0, "desc"]],
      },
    };
  },
  mounted() {
    this.userRole = Api.getUserRole();
    this.getDataCount();
    this.getData();
    this.getDataMerchant();

    this.fetchDataBarChart();
    this.fetchRevenue();
  },
  methods: {
    async getDataCount() {
      this.Api.get(`/transaction/count`)
        .then((res) => {
          this.count = res.data;
        })
        .catch((e) => {});
    },

    formatPrice(value) {
      return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getData() {
      this.Api.get("/merchant")
        .then((res) => {
          this.merchantList = res.data.content;
        })
        .catch((e) => {});

      this.Api.get("/vending")
        .then((res) => {
          this.vendingList = res.data.content;
        })
        .catch((e) => {});
    },
    clickRow(row) {
      if (row.data()) {
        // this.$router.push({name: 'AssignVending', params: {id: row.data().id}})
        this.$router.push({
          name: "AssignVendingDinamis",
          query: { vendingId: row.data().id },
          params: {
            id: row.data().id,
            row: row.data().row_slot ? row.data().row_slot : 5,
            col: row.data().col_slot ? row.data().col_slot : 8,
          },
        });
      }
    },
    getDataMerchant() {
      this.Api.get(`/merchant/byUser`)
        .then((res) => {
          this.merchant = res.data;
          this.form = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleChangeTab(param) {
      this.selectedDay = param;
    },

    fetchRevenue() {
      // Count hours
      this.Api.get("/transaction/count")
        .then((res) => {
          this.revenue = res.data;
        })
        .catch((e) => {});
    },

    fetchDataBarChart() {
      // Count hours
      this.Api.get("/transaction/countHours")
        .then((res) => {
          const data = res.data;
          data.map((item, key) => {
            this.barChartData[this.days[key]] = {
              data: item.data,
              labels: item.label,
            };
          });
        })
        .catch((e) => {});
    },
  },
};
</script>
