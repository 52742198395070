<template>
  <div class="card vm-card-widget border m-1">
    <div class="card-header">
      List Vending Machine yang dikelola monster
    </div>
    <div class="card-body pl-4 pr-4 pt-4">
      <div v-for="item in items" :key="item.id">
        <div class="row p-3 vm-card-widget border mb-3">
          <div class="col-md-3">
            <div>
              <span class="vm-card-merchant-label-type">{{ item.name }}</span>
            </div>
            <div>
              <span class="vm-card-merchant-value">{{ item.vending_id }}</span>
            </div>
            <div>
              <span class="vm-card-merchant-value">{{ item.address }}</span>
            </div>
          </div>
          <div class="col-md-2">
            <div>
              <span class="badge badge-pill badge-success">Ready</span>
            </div>
          </div>
          <div class="col-md-3 text-right">
            <div>
              <span class="vm-card-merchant-label-type">Jumlah Transaksi</span>
            </div>
            <div>
              <span class="vm-card-merchant-value-calculate">{{
                item.count ? item.count : 0
              }}</span>
            </div>
          </div>
          <div class="col-md-3 text-right">
            <div>
              <span class="vm-card-merchant-label-type">Nilai Transaksi</span>
            </div>
            <div>
              <span class="vm-card-merchant-value-calculate"
                >Rp {{ item.value ? formatPrice(item.value) : 0 }}</span
              >
            </div>
          </div>
          <div class="col-md-1 align-self-center text-right">
            <router-link
              :to="{
                name: 'AssignVendingDinamis',
                query: { vendingId: item.id }
                // params: {
                //   id: item.id,
                //   row: item.row_slot ? item.row_slot : 5,
                //   col: item.col_slot ? item.col_slot : 8,
                // },
              }"
            >
              <span>
                <span class="fas fa-chevron-right text-blue"> </span>
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@assets/scss/card.scss";

export default {
  name: "card-vending",
  props: ["items"],
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
