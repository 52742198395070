<template>
  <div>
    <div class="card vm-card-widget" style="height: 230px">
      <div class="card-header">
        Best Seller Product
      </div>
      <div class="card-body p-3">
        <div class="row">
          <div class="col">
            <div v-for="number in numberRange" :key="number">
              <div class="row d-flex align-items-center">
                <div class="col-2">
                  <img
                    src="https://vmstg.monstercode.net/cdn/product/1686818356340.jpeg"
                    width="100%"
                  />
                </div>
                <div class="col">
                  <div class="vm-product-label">ABCDEFG</div>
                  <div class="progress vm-progress-product">
                    <div
                      class="progress-bar bg-success w-75"
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div v-for="number in numberRange" :key="number">
              <div class="row d-flex align-items-center">
                <div class="col-2">
                  <img
                    src="https://vmstg.monstercode.net/cdn/product/1686818356340.jpeg"
                    width="100%"
                  />
                </div>
                <div class="col">
                  <div class="vm-product-label">ABCDEFG</div>
                  <div class="progress vm-progress-product">
                    <div
                      class="progress-bar bg-success w-75"
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@assets/scss/card.scss";
export default {
  name: "card-product",
  computed: {
    numberRange() {
      const start = 1;
      const end = 5;
      return Array.from(
        { length: end - start + 1 },
        (_, index) => start + index
      );
    },
  },
};
</script>
