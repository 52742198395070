<template>
  <div class="card vm-card-widget">
    <img class="card-img-top" :src="imageUrl" alt="Card image cap">
    <div class="card-body">
      <div class="vm-label-container">
        <i class="fas fa-circle" :style="{ color: color, fontSize: '10px' }"></i>
        {{ label }}
      </div>
      <div class="vm-value-container">
        <span>{{ value }}</span>
        <span>{{ value_detail }}</span>
      </div>
      <div class="vm-calculate-container">
        <span :style="{ color: color, marginRight: '5px' }"><i class="fas fa-chart-line" style="margin-right: 3px"></i>{{
          calculate }}</span>
        {{ calculate_detail }}
      </div>
    </div>
  </div>
</template>
<script>
import "@assets/scss/card.scss"

export default {
  name: 'card-calculate',
  props: {
    value: {
      type: Number,
      required: true,
    },
    value_detail: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    calculate: {
      type: Number,
      required: true
    },
    calculate_detail: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false
    }
  }
}
</script>