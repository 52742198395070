<template>
  <div>
    <div
      class="card p-4 mt-3 vm-card-merchant"
      v-for="item in items"
      :key="item.id"
    >
      <div class="row p-1">
        <div class="col-md-2">
          <img
            :src="item.image"
            :alt="env.app_name"
            class="brand-image"
            width="100"
            height="50"
          />
        </div>
        <div class="col-md-3 px-3">
          <div>
            <span class="vm-card-merchant-label">{{ item.name }} </span>
          </div>
          <div>
            <span class="vm-card-merchant-value">ID : -</span>
          </div>
        </div>
        <div class="col-md-3 text-right">
          <div>
            <span class="vm-card-merchant-label">
              Jumlah Transaksi Perbulan</span
            >
          </div>
          <div>
            <span class="vm-card-merchant-value">{{ item.count }} Item</span>
          </div>
        </div>
        <div class="col-md-3 text-right">
          <div>
            <span class="vm-card-merchant-label">
              Nilai Transaksi Perbulan</span
            >
          </div>
          <div>
            <span class="vm-card-merchant-value"
              >Rp {{ formatPrice(item.value) }}</span
            >
          </div>
        </div>
        <div class="col-md-1 align-self-center text-right">
          <div>
            <span>
              <span class="fas fa-chevron-right text-blue"> </span>
            </span>
          </div>
        </div>
      </div>
      <div
        class="row p-3 border m-1 rounded"
        v-for="item in item.vending"
        :key="item.id"
      >
        <div class="col-md-3">
          <div>
            <span class="vm-card-merchant-label-type">{{ item.name }}</span>
          </div>
          <div>
            <span class="vm-card-merchant-value">{{ item.vending_id }}</span>
          </div>
          <div>
            <span class="vm-card-merchant-value">{{ item.address }}</span>
          </div>
        </div>
        <div class="col-md-2">
          <div>
            <span class="badge badge-pill badge-success">Ready</span>
          </div>
        </div>
        <div class="col-md-3 text-right">
          <div>
            <span class="vm-card-merchant-label-type">Jumlah Transaksi</span>
          </div>
          <div>
            <span class="vm-card-merchant-value-calculate"
              >{{ item.count }} Item</span
            >
          </div>
        </div>
        <div class="col-md-3 text-right">
          <div>
            <span class="vm-card-merchant-label-type">Nilai Transaksi</span>
          </div>
          <div>
            <span class="vm-card-merchant-value-calculate"
              >Rp {{ formatPrice(item.value) }}</span
            >
          </div>
        </div>
        <div class="col-md-1 align-self-center text-right">
          <!-- <div @click="add" style="cursor: pointer">  -->
          <!-- <router-link :to="{ name: 'AssignVending', params: { id: item.id } }"> -->
          <router-link
            :to="{
              name: 'AssignVendingDinamis',
              query: { vendingId: item.id },
              params: {
                id: item.id,
                row: item.row_slot ? item.row_slot : 5,
                col: item.col_slot ? item.col_slot : 8,
              },
            }"
          >
            <span>
              <span class="fas fa-chevron-right text-blue"> </span>
            </span>
          </router-link>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@assets/scss/card.scss";

export default {
  name: "card-merchant",
  props: ["items"],
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
