<template>
  <div>
    <canvas id="chartCanvas" style="width: 100%"></canvas>
    <!-- <div class="row line-border mr-1 ml-1">

      <div class="col text-center">03.00</div>
      <div class="col text-center">06.00</div>
      <div class="col text-center">09.00</div>
      <div class="col text-center">12.00</div>
      <div class="col text-center">15.00</div>
      <div class="col text-center">18.00</div>
      <div class="col text-center">21.00</div>
      <div class="col text-center">24.00</div>
    </div> -->
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "bar-chart",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderBarChart();
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.updateChartData();
      },
    },
  },
  methods: {
    renderBarChart() {
      const chartCanvas = document
        .getElementById("chartCanvas")
        .getContext("2d");

      if (this.chart) {
        this.chart.destroy(); // Destroy the previous chart instance
      }

      const data = {
        labels: this.chartData?.labels || [],
        datasets: [
          {
            label: "",
            data: this.chartData?.data || [],
            backgroundColor: "#3267E3", // Bar color
          },
        ],
      };

      const options = {
        responsive: true,
        // maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          y: {
            beginAtZero: true,
          },
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
              },
              ticks: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
              },

              ticks: {
                display: false,
              },
            },
          ],
        },
      };

      this.chart = new Chart(chartCanvas, {
        type: "bar",
        data: data,
        options: options,
      });
    },
    updateChartData() {
      if (this.chart) {
        this.chart.data.labels = this.chartData.labels;
        this.chart.data.datasets[0].data = this.chartData.data;
        this.chart.update();
      }
    },
  },
};
</script>

<style>
.line-border {
  border-top: 2px solid #f0f3ff;
  font-weight: 500;
}
</style>
